import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import "./ProductForm.scss";
import { useSelector } from "react-redux";
import {
  selectLoggedInBusinessOwner,
  selectUser,
} from "../../redux/features/auth/authSlice";
import usePaymentUpdate from "../../customHook/usePaymentUpdate";
import ExpiredSubscription, {
  GracePeriod,
} from "../paymentUpdates/UpdatePayment";

const ProductForm = ({
  product,
  handleInputChange,
  saveProduct,
}) => {
  const admin = useSelector(selectLoggedInBusinessOwner);
  const currentUser = useSelector(selectUser);

  const { isInGracePeriod, isSubscriptionExpired } = usePaymentUpdate({
    currentUser: currentUser,
  });

  return (
    <>
      {admin || currentUser?.permissions?.addProducts ? (
        <>
          {isSubscriptionExpired ? (
            <>
              <ExpiredSubscription />
            </>
          ) : (
            <>
              {isInGracePeriod && (
                <>
                  <GracePeriod />
                </>
              )}
              <div className="product-group">
                {/* {isLoading && <Loader />} */}
                <h1>Add a Single Item</h1>
                <div className="product-form-group">
                  <form onSubmit={saveProduct}>
                    <div className="form-field">
                      <div>
                        <label>Item name*</label>
                        <input
                          type="text"
                          name="name"
                          value={product?.name}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Category*</label>
                        <input
                          type="text"
                          name="category"
                          value={product?.category}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-field">
                      <div>
                        <label>Cost Price*</label>
                        <input
                          type="text"
                          name="cost"
                          value={product?.cost}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div>
                        <label>Selling Price*</label>
                        <input
                          type="text"
                          name="price"
                          value={product?.price}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-field">
                      <div>
                        <label>Quantity*</label>
                        <input
                          type="text"
                          name="quantity"
                          value={product?.quantity}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div>
                        <label>Warehouse*</label>
                        <input
                          type="text"
                          name="warehouse"
                          value={product?.warehouse}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="form-field single-field">
                      <div>
                        <label>Description*</label>
                        <textarea
                          onChange={handleInputChange}
                          name="description"
                          value={product?.description}
                          cols="30"
                          rows="4"
                        ></textarea>
                        {/* <ReactQuill
                theme="snow"
                value={description}
                onChange={setDescription}
                modules={ProductForm.modules}
                // formats={ProductForm.formats}
              /> */}
                      </div>
                    </div>

                    <div className="submit-product-group">
                      <button type="submit">Add product</button>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="unauthorized-containers">
          <h1>Unauthorized</h1>
        </div>
      )}
    </>
  );
};


export default ProductForm;
