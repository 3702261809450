import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import {
  getProduct,
  getProducts,
  selectIsLoading,
  selectProduct,
  updateProduct,
} from "../../redux/features/product/productSlice";
import ProductForm from "../../components/productForm/ProductForm";

const initialState = {
  name: "",
  category: "",
  quantity: "",
  price: "",
  warehouse: "",
  cost: "",
  sku: "",
  description: "",
};

const EditProduct = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { products, isLoading } = useSelector((state) => state.product);

  const [product, setProduct] = useState(null);
  const [description, setDescription] = useState("");
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);


  useEffect(() => {
    const productToEdit = products?.find((product) => product._id === id);

    if (productToEdit) {
      setProduct(productToEdit);
      setFormData(productToEdit);
    }
  }, [products, id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const saveProduct = async (e) => {
    e.preventDefault();

    await dispatch(updateProduct({ id, formData }));
    await dispatch(getProducts());
    navigate("/inventory");
  };

  return (
    <div>
      {isLoading && <Loader />}
      <ProductForm
        product={product}
        setDescription={setDescription}
        description={description}
        handleInputChange={handleInputChange}
        saveProduct={saveProduct}
      />
    </div>
  );
};

export default EditProduct;
