import plusadd from "../../assets/home/plusadd.svg";
import crox from "../../assets/home/crox.svg";
import deleteIcon from "../../assets/home/deleteIcon.svg";
import { RxCrossCircled } from "react-icons/rx";
import Loader from "../../components/loader/Loader";
import "./addproduct.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import usePaymentUpdate from "../../customHook/usePaymentUpdate";
import ExpiredSubscription, { GracePeriod } from "../paymentUpdates/UpdatePayment";

export default function ProductGroupForm({
  mode,
  isLoading,
  admin,
  currentUser,
  formData,
  attributes,
  combinations,
  sku,
  price,
  warehouse,
  cost,
  options,
  inputRefs,
  handleInputChange,
  handleBlur,
  handleKeyPress,
  handleAddAttribute,
  handleDeleteAttribute,
  handleRemoveItem,
  handleDeleteCombination,
  handleSetCombinationName,
  handleSkuChange,
  handlePriceChange,
  handleCostChange,
  handleWarehouseChange,
  handleCopySkuToAll,
  handleCopyCostToAll,
  handleCopyPriceToAll,
  handleCopyWarehouseToAll,
  saveProductGroup,
  focusInput,
}) {
  const isAuthorized =
    mode === "add"
      ? admin || currentUser?.permissions?.addProducts
      : admin || currentUser?.permissions?.editProducts;

  const { isInGracePeriod, isSubscriptionExpired } = usePaymentUpdate({
    currentUser: currentUser,
  });

  return (
    <>
      {isAuthorized ? (
        <>
          {isSubscriptionExpired ? (
            <>
              <ExpiredSubscription />
            </>
          ) : (
            <>
              {isInGracePeriod && (
                <>
                  <GracePeriod />
                </>
              )}
              <div className="product-group">
                {isLoading && <Loader />}
                <h1>{mode === "add" ? "Add Item Group" : "Edit Item Group"}</h1>
                <div className="product-form-group">
                  <div className="form-field">
                    <div>
                      <label>Item Group Name*</label>
                      <input
                        name="groupName"
                        onChange={handleInputChange}
                        type="text"
                        value={formData.groupName}
                      />
                    </div>
                    <div>
                      <label>Category*</label>
                      <input
                        name="category"
                        onChange={handleInputChange}
                        type="text"
                        value={formData.category}
                      />
                    </div>
                  </div>
                  <div className="form-field single-field">
                    <div>
                      <label>Description*</label>
                      <textarea
                        onChange={handleInputChange}
                        name="description"
                        value={formData.description}
                        cols="30"
                        rows="4"
                      ></textarea>
                    </div>
                  </div>

                  {attributes.map((attr, index) => (
                    <div
                      key={index}
                      className="form-field product_group_attributes"
                    > 
                      <div>
                        <label>Attribute*</label>
                        <input
                          name={`attribute_${index}`}
                          value={attr}
                          onChange={(e) => handleBlur(e, index)}
                          type="text"
                        />
                      </div>
                      <div>
                        <label>Options*</label>
                        <div
                          onClick={() => focusInput(inputRefs.current[index])}
                          className="options"
                        >
                          <ul>
                            {options[index]?.attr.map((option, index2) => (
                              <li key={index2}>
                                {!option.showInput && (
                                  <span>
                                    {option.value}
                                    <img
                                      onClick={() =>
                                        handleRemoveItem(index, index2)
                                      }
                                      src={crox}
                                      alt="cancel"
                                    />
                                  </span>
                                )}
                              </li>
                            ))}
                            {options[index]?.attr.some(
                              (option) => option.showInput
                            ) && (
                              <input
                                ref={inputRefs.current[index]}
                                type="text"
                                onKeyDown={(e) => handleKeyPress(e, index)}
                                inputMode="text"
                                // placeholder="Enter value, Press enter"
                                pattern="[A-Za-z0-9,]*"
                              />
                            )}
                          </ul>
                        </div>
                      </div>
                      <img
                        src={deleteIcon}
                        onClick={() => handleDeleteAttribute(index)}
                        alt="delete"
                      />
                    </div>
                  ))}

                  <div className="product_group_instructions">
                    <ul>
                      <li>
                        Avoid using "-" or "/" as they are used for combination.
                      </li>
                      <li>Separate different options by pressing enter.</li>
                      <li>
                        If you have more than one attributes:{" "}
                        <ul>
                          <li>Every first option in the previous attribute</li>
                          <li>
                            Matches every first option in the second option and
                            so on.
                          </li>
                          <li>
                            As you may be able to figure in the item name
                            generated.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>

                  <span onClick={handleAddAttribute}>
                    <img src={plusadd} alt="add" /> Add More Attributes
                  </span>
                </div>

                <div className="group-items-table">
                  <table>
                    <thead>
                      <tr>
                        <th>Item Name</th>
                        <th>
                          SKU <p onClick={handleCopySkuToAll}>Generate SKU</p>
                        </th>
                        <th>
                          Cost Price{" "}
                          <p onClick={handleCopyCostToAll}>Copy to All</p>
                        </th>
                        <th>
                          Selling Price{" "}
                          <p onClick={handleCopyPriceToAll}>Copy to All</p>
                        </th>
                        <th>
                          Warehouse{" "}
                          <p onClick={handleCopyWarehouseToAll}>Copy to All</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {combinations.length > 0 ? (
                        combinations.map((com, index) => (
                          <tr key={index} className="individual_item_group">
                            <td className="group-item-name">
                              <textarea
                                onChange={(e) =>
                                  handleSetCombinationName(e, index)
                                }
                                name="item-name"
                                value={com}
                                cols="30"
                                rows="2"
                              ></textarea>
                            </td>
                            <td>
                              <textarea
                                onChange={(e) => handleSkuChange(e, index)}
                                name="sku"
                                value={sku[index]}
                                rows="2"
                              ></textarea>
                            </td>
                            <td>
                              <textarea
                                onChange={(e) => handleCostChange(e, index)}
                                name="cost"
                                value={cost[index]}
                                rows="2"
                              ></textarea>
                            </td>
                            <td>
                              <textarea
                                onChange={(e) => handlePriceChange(e, index)}
                                name="price"
                                value={price[index]}
                                rows="2"
                              ></textarea>
                            </td>
                            <td>
                              <textarea
                                onChange={(e) =>
                                  handleWarehouseChange(e, index)
                                }
                                name="warehouse"
                                value={warehouse[index]}
                                rows="2"
                              ></textarea>
                            </td>
                            <RxCrossCircled
                              onClick={() => handleDeleteCombination(com)}
                              className="delete-combinations"
                              size={16}
                            />
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5">
                            <p>Enter attributes to generate combinations...</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="submit-product-group">
                  <button onClick={saveProductGroup}>
                    {mode === "add" ? "Add Products" : "Update Products"}
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="unauthorized-containers">
          <h1>Unauthorized</h1>
        </div>
      )}
    </>
  );
}
